import React, { useState, useEffect } from 'react';

const brands = [
    require('../../img/spine-colorado.png'),
    require('../../img/texas-spine-logo@3x.png'),
    require('../../img/california-ortho-and-spine-logo-new@3x.png'),
    require('../../img/bass-logo-new@3x.png'),
    require('../../img/epiccare-logo-new@3x.png'),
    require('../../img/20_files/01.png'),
    require('../../img/20_files/02.png'),
    require('../../img/20_files/03.png'),
    require('../../img/20_files/04.png'),
    require('../../img/20_files/05.png'),
    require('../../img/20_files/06.png'),
    require('../../img/20_files/07.png'),
    require('../../img/20_files/08.png'),
    require('../../img/20_files/09.png'),
    require('../../img/20_files/10.png'),
    require('../../img/20_files/11.png'),
    require('../../img/20_files/12.png'),
    require('../../img/20_files/13.png'),
    require('../../img/20_files/14.png'),
    require('../../img/20_files/15.png'),
    require('../../img/20_files/16.png'),
    require('../../img/20_files/17.png'),
    require('../../img/20_files/18.png'),
    require('../../img/20_files/19.png'),
    require('../../img/20_files/20.png'),
];

function getDeviceType() {
    const width = window.innerWidth;
    
    if (width < 768) {
        return 'mobile';
    } else if (width >= 768 && width < 992) {
        return 'tablet';
    } else {
        return 'desktop';
    }
}



const BrandCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const noImageToShow = (getDeviceType() === 'mobile') ? 2 : ((getDeviceType() === 'tablet') ? 3 : 4);
    const totalSets = Math.ceil(brands.length / noImageToShow);
  
    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSets);
      }, 3000);
  
      return () => clearInterval(timer);
    }, [totalSets]);
  
    return (
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <div style={{
          display: 'flex',
          transition: 'transform 0.5s ease',
          transform: `translateX(-${currentIndex * 100}%)`,
        }}>
          {Array.from({ length: totalSets }).map((_, setIndex) => (
            <div key={setIndex} style={{ display: 'flex', flexShrink: 0, width: '100%', justifyContent: 'space-around' }}>
              {brands.slice(setIndex * noImageToShow, setIndex * noImageToShow + noImageToShow).map((brand, index) => (
                <img
                  key={index}
                  src={brand || "/placeholder.svg"}
                  alt={`Brand ${setIndex * noImageToShow + index + 1}`}
                style={{
                    width: `135px`,
                    height: 'auto',
                    objectFit: 'contain',
                }}
                />
              ))}
            </div>
          ))}
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
        }}>
          {Array.from({ length: totalSets }).map((_, index) => (
            <div
              key={index}
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: index === currentIndex ? '#007bff' : '#ccc',
                margin: '0 5px',
              }}
            />
          ))}
        </div>
      </div>
    );
  };

export default BrandCarousel;